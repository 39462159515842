import {
  Dns,
  GppMaybe,
  Groups,
  Inventory,
  LocalShipping,
  Navigation,
  People,
  RemoveRedEye,
  Schema,
  Sync,
  TempleHindu,
  ViewStream,
} from "@mui/icons-material";
import PATH from "../../../Routing/PATHS";

var loginData = localStorage.getItem("login");
var loginValue = JSON.parse(loginData);

const netsuiteSubMenus = [
  {
    menu: "Current",
    link: null,
    icon: <Dns />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "Current Stock",
        link: PATH.NETSUITE_CURRENT_STOCK,
      },
      {
        menuName: "Current Location",
        link: PATH.NETSUITE_CURRENT_BRANCH,
      },
      {
        menuName: "Current Supplier",
        link: PATH.NETSUITE_CURRENT_SUPPLIER,
        // link: "/supplier/current_supplier",
      },
      {
        menuName: "Current Groups",
        link: PATH.NETSUITE_CURRENT_STOCKGROUP,
        // link: "/groups/current_stock_groups",
      },
      {
        menuName: "Current Price Policy",
        link: PATH.NETSUITE_CURRENT_POLICY,
        // link: "/current/price_policy",
      },
    ],
  },
  // {
  //   menu: "New System",
  //   link: null,
  //   icon: <Inventory />,
  //   multiLevel: true,
  //   multiMenus: [
  //     {
  //       menuName: "New System Stock",
  //       link: PATH.NETSUITE_NEW_STOCK,
  //       // link: "/stock/new_system_stock",
  //     },
  //     {
  //       menuName: "New System Location",
  //       link: PATH.NETSUITE_NEW_BRANCH,
  //       // link: "/stock/new_system_branch",
  //     },
  //     {
  //       menuName: "New System Supplier",
  //       link: PATH.NETSUITE_NEW_SUPPLIER,
  //       // link: "/supplier/new_system_supplier",
  //     },
  //     {
  //       menuName: "New System Groups",
  //       link: PATH.NETSUITE_NEW_STOCKGROUP,
  //       // link: "/groups/new_system_stock_groups",
  //     },
  //     {
  //       menuName: "New System Price Policy",
  //       link: PATH.NETSUITE_NEW_PRICE,
  //       // link: "/new-system/price_policy",
  //     },
  //   ],
  // },
  {
    menu: "Orders",
    link: null,
    icon: <ViewStream />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "All Orders",
        link: PATH.NETSUITE_ORDERS_ALL,
        // link: "/orders/list_orders"
      },
      {
        menuName: "CSV Export",
        link: PATH.NETSUITE_ORDERS_EXPORT,
        // link: "/orders/csv_export"
      },
    ],
  },

  {
    menu: "Visibility",
    link: null,
    icon: <RemoveRedEye />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "Temple and Webster",
        link: null,
        multiLevel: true,
        multiMenus: [
          {
            menuName: "Order File",
            link: PATH.ONLINE_TEMPLE_ORDER_FILE,
          },
          {
            menuName: "Orders",
            link: PATH.NETSUITE_VISIBILITY_TW_ORDER,
          },
          {
            menuName: "ACR Tracking File",
            link: PATH.ONLINE_TEMPLE_ACR_SO_FILE,
          },
          {
            menuName: "Inbound Shipment",
            link: PATH.ONLINE_TW_NETSUITE_CONTAINER,
          },
        ]
      },
      {
        menuName: "Marketplaces",
        link: PATH.NETSUITE_MARKETPLACE_ORDER,
        // link: "/orders/csv_export"
      },
    ],
  },

  {
    menu: "WorkFlow",
    link: null,
    icon: <Schema />,
    multiLevel: true,
    multiMenus: [
      
      {
        menuName: "Marketplace",
        link: PATH.NETSUITE_WORKFLOW_MARKETPLACE_ORDER,
        // link: "/orders/csv_export"
      },
    ],
  },
  // {
  //   menu: "Temple and Webster",
  //   link: null,
  //   icon: <TempleHindu />,
  //   multiLevel: true,
  //   multiMenus: [
  //     {
  //       menuName: "SOH",
  //       link: PATH.ONLINE_TEMPLE_SOH,
  //       // link: "/account/current_customers",
  //     },

  //     {
  //       menuName: "Orders By Location",
  //       link: PATH.ONLINE_TEMPLE_ORDERBYBRANCH,
  //       // link: "/account/current_customers",
  //     },
  //     // {
  //     //   menuName: "Upload Shipping Label",
  //     //   link: PATH.ONLINE_TEMPLE_UPLOADSHIPPINGLABEL,
  //     //   // link: "/account/current_customers",
  //     // },
  //     {
  //       menuName: "Upload CSV",
  //       link: PATH.ONLINE_TEMPLE_UPLOADORDER,
  //       // link: "/account/current_customers",
  //     },
  //     {
  //       menuName: "TW Despatch Report",
  //       link: PATH.ONLINE_TW_ORDERS_TESTING,
  //       // link: "/account/current_customers",
  //     },
  //   ],
  // },
  {
    menu: "Temple and Webster",
    link: null,
    icon: <TempleHindu />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "SOH",
        link: PATH.ONLINE_TEMPLE_SOH,
      },
      
      {
        menuName: "Orders By Location",
        link: PATH.ONLINE_TEMPLE_ORDERBYBRANCH,
      },

      {
        menuName: "Upload Shipping Label",
        link: PATH.ONLINE_TEMPLE_UPLOADSHIPPINGLABEL,
        // link: "/account/current_customers",
      },
      // Conditional rendering for "Upload CSV" option based on userlevel
      loginValue?.UserLevel === 1 && {
        menuName: "Upload CSV",
        link: PATH.ONLINE_TEMPLE_UPLOADORDER,
      },
      {
        menuName: "TW Despatch Report",
        link: PATH.ONLINE_TW_ORDERS_TESTING,
      },


    ].filter(Boolean), // Remove undefined values from the array
  },
  // {
  //   menu: "Exception Report",
  //   link: null,
  //   icon: <GppMaybe />,
  //   multiLevel: true,
  //   multiMenus: [
  //     {
  //       menuName: "Price Orders",
  //       link: PATH.NETSUITE_EXCEPTION_PRICEORDER,
  //       // link: "/orders/list_orders_price_exception",
  //     },
  //     {
  //       menuName: "Zero Price",
  //       link: PATH.NETSUITE_EXCEPTION_ZERO,
  //       // link: "/stock/exceptions/zero_price",
  //     },
  //     {
  //       menuName: "Long Description",
  //       link: PATH.NETSUITE_EXCEPTION_LONGDESCRIPTION,
  //       // link: "/stock/exceptions/title_exception",
  //     },
  //     {
  //       menuName: "Category",
  //       link: PATH.NETSUITE_EXCEPTION_CATEGORY,
  //       // link: "/stock/exceptions/category_exception",
  //     },
  //     {
  //       menuName: "Image Missing",
  //       link: PATH.NETSUITE_EXCEPTION_IMAGE,
  //       // link: "/stock/exceptions/image_missing",
  //     },
  //     {
  //       menuName: "Sync Status",
  //       link: PATH.NETSUITE_EXCEPTION_SYNC,
  //       // link: "/stock/exceptions/sync_status",
  //     },
  //   ],
  // },
];

export default netsuiteSubMenus;
