import React, { useEffect, useState, useRef } from "react";
import {
    ArrowBack,
    ArrowForward,
    Check,
    Clear,
    Close,
    Download,
    FilterList,
} from "@mui/icons-material";
import PATH from "../../../../Routing/PATHS";
import { httpclient } from "../../../../utils/httpClient";
import {
    Box,
    Button,
    Card,
    Collapse,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    styled,
    TextField,
    Snackbar,
    Autocomplete,
} from "@mui/material";

import MuiAlert from "@mui/material/Alert";


import { useLocation, useNavigate } from "react-router";
import { CSVLink } from "react-csv";
import { dateFormatter } from "../../../../utils/DataGrid/dateFormatter";
import AdjustedAllocationTable from "../Table";
import { Link } from "react-router-dom";
import moment from "moment/moment";

//import { useLocation } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// order_created = DSCO order created date
// lastUpdate = DSCO last update
// consumerOrderDate = Consumer order date

const columns = [
    { id: "storeCode", name: "Branch", tooltip: "Store Code" },
    { id: "stockCode", name: "Style", tooltip: "Stock Code" },
    { id: "qtyAllocated", name: "Qty Allocated", tooltip: "QtyAllocated (Forecasted Quantity)" },
    { id: "openingSOH", name: "Opening SOH", tooltip: "SOH (Quantity)" },
    { id: "periodSales", name: "Period Sales", tooltip: "Period Sales (Forecasted Sales Period)" },
    { id: "closingStock", name: "Closing Stock", tooltip: "(Current SOH + Incoming SOH from master files)" },
    { id: "minLevel", name: "Min Level", tooltip: "Minimum Stock Level" },
    { id: "cartonQty", name: "Carton Quantity", tooltip: "Carton Qty" },
    { id: "ctnsAllocated", name: "Ctns Allocated", tooltip: "QtyAllocated / Carton Qty" },
    { id: "ctnCubic", name: "Ctn Cubic", tooltip: "CtnCubic" },
    { id: "totalCubic", name: "Total Cubic", tooltip: "Ctns Allocated * CtnCubic" },
    { id: "rawSuggestion", name: "Raw Suggestion", tooltip: "Raw Suggestion" },
    { id: "weeklySales", name: "Weekly Sales", tooltip: "Period Sales / 6" },
    { id: "closingMinusLead", name: "Closing Minus Lead", tooltip: "Closing Stock - ( 2 * Weekly Sales)" },
    { id: "differentialPieces", name: "Differential Pieces", tooltip: "ClosingMinusLead - Min Level" },
    { id: "differentialCartons", name: "Differential Cartons", tooltip: "Differential Pieces /  Carton Qty" },
];

const columnsList = [
    { id: "ID", name: "Request ID" },
    { id: "Total_stocks", name: "Total Stocks" },
    { id: "Status", name: "Status" },
    { id: "Download_CSV", name: "Download CSV" },
    { id: "View_Data", name: "View Data" },
];


const FilteredBox = styled(Box)(({ theme }) => ({
    background: "#f9f9f9",
    padding: "5px 10px",
    borderRadius: "5px",
    "& p": {
        margin: "3px 0",
        marginRight: "10px",
        display: "inline-block",
        background: "#dedede",
        borderRadius: "10px",
        padding: "2px 5px",
    },
    "& svg": {
        fontSize: "15px",
        cursor: "pointer",
        position: "relative",
        top: "3px",
        background: theme.palette.primary.dark,
        color: "#fff",
        borderRadius: "50%",
        padding: "2px",
        marginLeft: "2px",
    },
}));

const Header = styled("div")(({ theme }) => ({
    "& h1": {
        color: theme.palette.primary.dark,
        margin: "0",
    },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));

const AdjustedAllocation = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const buttonRef = useRef(null);

    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [viewDetails, setViewDetails] = useState({});
    const [openStatusDialog, setOpenStatusDialog] = useState(false);
    const [customStatus, setCustomStatus] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowsList, setRowsList] = useState([]);
    const [exportRows, setExportRows] = useState([]);
    const [rowChecked, setRowChecked] = useState([]);
    const [company, setCompany] = useState([]);
    const [selectedChecked, setSelectedChecked] = useState([]);
    const [orderChecked, setOrderChecked] = useState([]);

    const [status, setStatus] = useState("");
    const [selected, setSelected] = useState([]);
    const [selected1, setSelected1] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [backdropLoader, setBackdropLoader] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingList, setLoadingList] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [singleLoading, setSingleLoading] = useState(false);
    const [direction, setDirection] = useState(false);
    const [currentColumn, setCurrentColumn] = useState("");
    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(
        configRowPerPage && configRowPerPage
            ? configRowPerPage && configRowPerPage
            : 20
    );

    const [rowsPerPage, setRowsPerPage] = useState(
        configRowPerPage && configRowPerPage
            ? configRowPerPage && configRowPerPage
            : 20
    );
    const [total, setTotal] = useState("");
    const [filterOpen, setFilterOpen] = useState(false);
    const [progress, setProgress] = useState(null);
    const [timeStamp, setTimeStamp] = useState(null);
    const [complete, setComplete] = useState(1);
    const [com, setCom] = useState(null);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");
    const [request, setRequest] = useState("");
    const [companyList, setCompanyList] = useState([]);
   
    const [downloadForecastData, setDownloadForecastData] = useState(null);
    const [filterData, setFilterData] = useState({

        phoneNumber: "",

        soldTo: "",
        soldToName: "",

        remove: false,
    });

    const [submittedData, setSubmittedData] = useState({

        phoneNumber: "",

        soldTo: "",
        soldToName: "",

        submit: false,
    });


    useEffect(() => {
        if (

            filterData.phoneNumber === "" &&

            filterData.soldTo === "" &&
            filterData.soldToName === ""

        ) {
            setSubmittedData({
                ...submittedData,
                submit: false,
            });
        }

        if (filterData.phoneNumber === " ") filterData.phoneNumber = "";

        if (filterData.soldTo === " ") filterData.soldTo = "";
        if (filterData.soldToName === " ") filterData.soldToName = "";

        filterData.remove === true && handleFilter();

    }, [filterData]);

   
    const state = JSON.parse(localStorage.getItem("passAPI"));
    useEffect(() => {
        console.log("passAPI state here??", state);
        if (state === true) {
            let currentpolicy = JSON.parse(localStorage.getItem("adjusted_allocation_filter"));
            currentpolicy !== null && setFilterData(currentpolicy);


            (currentpolicy == null)
                ? getOrders()
                :
                currentpolicy.phoneNumber == "" &&
                    currentpolicy.soldTo == "" &&
                    currentpolicy.soldToName == "" &&

                    currentpolicy.removed == false
                    ? getOrders()
                    : console.log("orders");
        }

    }, [state]);

    const formatStockCodes = (stockCodesString) => {
        // Split the string into an array based on newline character
        const stockCodesArray = stockCodesString.split("\n");

        // Join the array elements using commas
        const formattedStockCodes = stockCodesArray.join(",");

        return formattedStockCodes;
    };

    const defaultHistoricalSalesStart = "2022-05-01";
    const defaultHistoricalSalesEnd = "2023-05-30";
    const defaultSupplierLeadTimeInWeeks = 4;
    const defaultDefaultDemand = "HIST";
    const defaultStockCodes = "";

    let apiCallUrl;

    if (props?.navigateState?.filterData) {
        const {
            supplierLeadTime,
            custLeadTimeDays,
            stockCodes,
            customerID,
            coverageBuffer,
            cycleFrequency,
            defaultDemand,
            defaultForecast,
            smoothing,
            recentSalesWeek,
            useSalesDataSince,
            historicalSalesStart,
            salesExcludeMonths,
            historicalSalesEnd,
            globalAllocRamping,
            globalSales,
            meanTrimming,
            startOfTheWeek,
            importedDate,
            otherSettings,
            currentProductOnly,
            dataRetrieveOnly,
            ignoreSupplier,
            standardAllocations,
            adjustedAllocations,
            allocateToMSLOnly,
        } = props?.navigateState?.filterData;
        let defaultDemandDependentParameters;

        if (defaultDemand === "RECENT") {
            defaultDemandDependentParameters = `?recentSalesWeek=${recentSalesWeek}`;
        } else if (defaultDemand === "HIST") {
            defaultDemandDependentParameters = `?historicalSalesStart=${historicalSalesStart}&historicalSalesEnd=${historicalSalesEnd}`;
        } else {
            defaultDemandDependentParameters = `?`;
            // defaultDemandDependentParameters = `?salesExcludeMonths=${salesExcludeMonths}`;
        }
        //const [updatedDate, tempSessionId] = importedDate.split(": ");
        apiCallUrl = `${props.forecastPassedUrl}${defaultDemandDependentParameters}&startOfTheWeek=${startOfTheWeek}&salesExcludeMonths=${salesExcludeMonths}&meanTrimming=${meanTrimming}&globalSales=${globalSales}&supplierLeadTime=${supplierLeadTime}&coverageBuffer=${coverageBuffer}&cycleFrequency=${cycleFrequency}&defaultForecast=${defaultForecast}&custLeadTimeDays=${custLeadTimeDays}&defaultDemand=${defaultDemand}&smoothing=${smoothing}&useSalesDataSince=${useSalesDataSince}&globalAllocRamping=${globalAllocRamping}&stockCodes=${formatStockCodes(
            stockCodes
        )}`;
        // &updatedDate=${updatedDate}&tempSessionId=${tempSessionId}`;
    } else {
        apiCallUrl = `${props.forecastPassedUrl}?historicalSalesStart=${defaultHistoricalSalesStart}&historicalSalesEnd=${defaultHistoricalSalesEnd}&supplierLeadTime=${defaultSupplierLeadTimeInWeeks}&defaultDemand=${defaultDefaultDemand}`;
    }



    const getList = () => {
        setLoadingList(true);
        httpclient
            .get(`replenishment/lists`)
            .then(({ data }) => {
                if (data) {

                    setRowsList(data.data);
                    setLoadingList(false);
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location = "/login";
                }
            });
    };




    const getOrders = () => {

        // var timestamp = new Date().getTime();
        var timestamp = new Date().getTime() % 1000000;

        setTimeStamp(timestamp)
        localStorage.setItem("passAPI", false);
        httpclient
            .get(`${apiCallUrl}&version=v2&requestID=${timestamp}&pagination=${rowsPerPage}&page=${page}`)
            .then(({ data }) => {
                if (data) {

                    // setRows(data.data);
                    // setDownloadForecastData(data.download_link);

                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location = "/login";
                }
            });

    };




    const getLoading = (row) => {
        setRequest(/(.+?)\((.*?)\)/.exec(row.ID));
        setLoading(true);
        httpclient
            .get(`replenishment/liststockcodes/${row.ID}`)
            .then(({ data }) => {
                if (data) {
                    if (data.progress === 100 && (data.complete === 2)) {
                        setCom("Generating Allocation...")
                    }
                    if (data.complete === 0) {
                        console.log("here??", data);
                        setRows(data.data);
                        setDownloadForecastData(data.download_link);
                        setLoading(false);
                        setComplete(data.complete);
                    }
                    else {

                        setLoading(true);
                    }
                    setProgress(data.progress);



                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location = "/login";
                }
            });
    };

    // useEffect(() => {
    //     if (timeStamp !== null && (complete === 1 || complete === 2)) {
    //         setLoading(true)
    //         const intervalId = setInterval(getLoading, 10 * 1000); // Fetch data every 60 seconds
    //         return () => clearInterval(intervalId);
    //     }
    // }, [timeStamp, complete]);
    useEffect(() => {
        // if (timeStamp !== null) {
        getList();
        const intervalId = setInterval(getList, 10 * 1000); // Fetch data every 60 seconds
        return () => clearInterval(intervalId);
        // }
    }, []);

    // useEffect(() => {

    //     const fetchInitialData =  () => {
    //        getLoading();
    //     };


    //     fetchInitialData();


    //     if (timeStamp !== null && progress !== 100) {
    //         const intervalId = setInterval(() => {
    //             getLoading();
    //         }, 30 * 1000);


    //         return () => clearInterval(intervalId);
    //     }
    // }, [timeStamp, progress]);



    useEffect(() => {
        getAllCompany();
    }, []);


    const getAllCompany = () => {
        httpclient
            .get(`replenishment/store-soh`)
            .then(({ data }) => {
                if (data.success) {
                    setCompanyList(data.branchList);
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location = "/login";
                }
            });
    };




    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "soldTo") {
            setFilterData({
                ...filterData,
                soldToName: value,
                remove: false,
            });
        }

    };

    const handleChangeSoldTo = (value) => {
        setFilterData({
            ...filterData,
            soldTo: value !== null ? value.id : "",
            soldToName: value !== null ? value.name : "",
            remove: false,
        });
    };



    const handleFilter = () => {
        setSubmittedData({
            ...submittedData,

            phoneNumber: filterData.phoneNumber,

            soldTo: filterData.soldTo,
            soldToName: filterData.soldToName,

            submit: true,
        });

        filterData.remove = true;

        // const orderlistFilterNew = {
        //   ...filterData,
        //   order_status: "Recieved", // Set order_status to "new" for orderlist_filter_new
        // };
        localStorage.setItem("adjusted_allocation_filter", JSON.stringify(filterData));
        // localStorage.setItem("orderlist_filter_new", JSON.stringify(orderlistFilterNew)); // Save orderlist_filter_new in localStorage

        setLoading(true);
        if (

            filterData.phoneNumber ||

            filterData.soldTo ||
            filterData.soldToName

        ) {


            httpclient
                .get(
                    `${apiCallUrl}&storeCode=${filterData.soldToName}&stockCode=${filterData.phoneNumber}&pagination=${rowsPerPage}&page=${1}`
                )
                .then(({ data }) => {
                    if (data.success) {
                        setRows(data.data);
                        setDownloadForecastData(data.download_link);
                        setLoading(false);
                    }
                });
        } else {
            getOrders();
        }
    };

    const hadleFilterOpen = () => {
        setFilterOpen((prev) => !prev);
    };

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        setFilterData({
            ...filterData,
            [name]: value,
            remove: false,
        });
    };

    const handleRemove = (data) => {
        if (data === "soldTo") {
            setFilterData(prevFilterData => ({
                ...prevFilterData,
                soldToName: "",
            }));
            setSubmittedData(prevSubmittedData => ({
                ...prevSubmittedData,
                soldToName: "",
            }));
        } else {
            setFilterData(prevFilterData => ({
                ...prevFilterData,
                [data]: "",
                remove: true,
            }));
            setSubmittedData(prevSubmittedData => ({
                ...prevSubmittedData,
                [data]: "",
            }));
        }
    };




    //   const handleSort = (column) => {
    //     setDirection((prevDirection) => !prevDirection);
    //     setCurrentColumn(column);
    //     submittedData.submit
    //       ? httpclient
    //         .get(
    //           `new-system/netsuite/exceptional-report?reportType=${filterData.exceptionalOrder
    //           }&newSystemOrderNumber=${filterData.newSystemOrderNumber
    //           }&phoneNumber=${filterData.phoneNumber}&isShippingLabelGenerate=${filterData.isShippingLabelGenerate}&order_status=${filterData.order_status
    //           }&custom_order_status=${filterData.customStatus}&shipment=${filterData.shipment}&billToName=${filterData.customerName
    //           }&soldTo=${filterData.soldTo}&stockCode=${filterData.stockCode
    //           }&invoiceStatus=${filterData.invoiceStatus}&startDate=${filterData.startDate || ""
    //           }&endDate=${filterData.endDate || ""}&despatchByStartDate=${filterData.despatchByStartDate || ""
    //           }&despatchByEndDate=${filterData.despatchByEndDate || ""
    //           }&netsuiteOrderNumber=${filterData.netsuiteOrderNumber || ""
    //           }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
    //           }&pagination=${rowsPerPage}&page=${page}`
    //         )
    //         .then(({ data }) => {
    //           if (data.success === true) {
    //             setRows(data.data);
    //             setTotal(data.meta.total);
    //             setRowsPerPage(parseInt(data.meta.per_page));
    //             setPage(data.meta.current_page);
    //             setFrom(data.meta.from);
    //             setTo(data.meta.to);
    //             setLoading(false);
    //           }
    //         })
    //       : httpclient
    //         .get(
    //           `new-system/netsuite/exceptional-report?sort_by=${column}&direction=${!direction ? "asc" : "desc"
    //           }&pagination=${rowsPerPage}`
    //         )
    //         .then(({ data }) => {
    //           if (data.success === true) {
    //             setRows(data.data);
    //           }
    //         });
    //   };

    //   const handleChangePage = (e, page) => {
    //     setLoading(true);
    //     submittedData.submit
    //       ? httpclient
    //         .get(
    //           `new-system/netsuite/exceptional-report?reportType=${filterData.exceptionalOrder
    //           }&newSystemOrderNumber=${filterData.newSystemOrderNumber
    //           }&phoneNumber=${filterData.phoneNumber}&isShippingLabelGenerate=${filterData.isShippingLabelGenerate}&order_status=${filterData.order_status
    //           }&custom_order_status=${filterData.customStatus}&shipment=${filterData.shipment}&billToName=${filterData.customerName
    //           }&soldTo=${filterData.soldTo}&stockCode=${filterData.stockCode
    //           }&invoiceStatus=${filterData.invoiceStatus}&startDate=${filterData.startDate || ""
    //           }&endDate=${filterData.endDate || ""}&despatchByStartDate=${filterData.despatchByStartDate || ""
    //           }&despatchByEndDate=${filterData.despatchByEndDate || ""
    //           }&netsuiteOrderNumber=${filterData.netsuiteOrderNumber || ""
    //           }&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
    //           }&pagination=${rowsPerPage}&page=${page}`
    //         )
    //         .then(({ data }) => {
    //           if (data.success === true) {
    //             setRows(data.data);
    //             setTotal(data.meta.total);
    //             setRowsPerPage(parseInt(data.meta.per_page));
    //             setPage(data.meta.current_page);
    //             setFrom(data.meta.from);
    //             setTo(data.meta.to);
    //             setLoading(false);
    //           }
    //         })
    //       : httpclient
    //         .get(
    //           `new-system/netsuite/exceptional-report?direction=${direction ? "asc" : "desc"
    //           }&pagination=${rowsPerPage}&page=${page}`
    //         )
    //         .then(({ data }) => {
    //           if (data.success === true) {
    //             setRows(data.data);
    //             setTotal(data.meta.total);
    //             setRowsPerPage(parseInt(data.meta.per_page));
    //             setPage(data.meta.current_page);
    //             setFrom(data.meta.from);
    //             setTo(data.meta.to);
    //             setLoading(false);
    //           }
    //         });
    //   };

    //   const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value);
    //     // setLoading(true);

    //     localStorage.setItem("configRowPerPage", event.target.value);

    //     submittedData.submit
    //       ? httpclient
    //         .get(
    //           `new-system/netsuite/exceptional-report?reportType=${filterData.exceptionalOrder
    //           }
    //           &newSystemOrderNumber=${filterData.newSystemOrderNumber
    //           }&phoneNumber=${filterData.phoneNumber}&isShippingLabelGenerate=${filterData.isShippingLabelGenerate}&order_status=${filterData.order_status
    //           }&custom_order_status=${filterData.customStatus}&billToName=${filterData.customerName}&soldTo=${filterData.soldTo
    //           }&stockCode=${filterData.stockCode}&shipment=${filterData.shipment
    //           }&invoiceStatus=${filterData.invoiceStatus}&startDate=${filterData.startDate || ""
    //           }&startDate=${filterData.startDate || ""}&despatchByStartDate=${filterData.despatchByStartDate || ""
    //           }&despatchByEndDate=${filterData.despatchByEndDate || ""
    //           }&netsuiteOrderNumber=${filterData.netsuiteOrderNumber || ""
    //           }&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
    //           }&pagination=${+event.target.value}&page=${page}`
    //         )
    //         .then(({ data }) => {
    //           setLoading(true);
    //           if (data.success === true) {
    //             setRows(data.data);
    //             setTotal(data.meta.total);
    //             setRowsPerPage(parseInt(data.meta.per_page));
    //             setPage(data.meta.current_page);
    //             setFrom(data.meta.from);
    //             setTo(data.meta.to);
    //             setLoading(false);
    //           }
    //         })
    //       : httpclient
    //         .get(
    //           `new-system/netsuite/exceptional-report?direction=${direction ? "asc" : "desc"
    //           }&pagination=${+event.target.value}&page=${1}`
    //         )
    //         .then(({ data }) => {
    //           setLoading(true);
    //           if (data.success === true) {
    //             setRows(data.data);
    //             setTotal(data.meta.total);
    //             setRowsPerPage(parseInt(data.meta.per_page));
    //             setFrom(data.meta.from);
    //             setTo(data.meta.to);
    //             setPage(data.meta.current_page);
    //             setLoading(false);
    //           }
    //         });
    //   };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };


    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                    <Header>
                        <h1>List Replenishment Allocation Adjusted</h1>
                    </Header>
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >

                    <Button
                        download
                        color="primary"
                        variant="contained"
                        href={`${downloadForecastData}`}
                        disabled={downloadForecastData === null}
                        // onClick={handleDownloadCsv}

                        style={{ marginRight: "10px" }}
                    >
                        DOWNLOAD CSV <Download style={{ marginLeft: "5px" }} fontSize="small" />
                    </Button>



                    <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
                        Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
                    </Button>
                </Grid>


                {/* Filter */}
                <Grid item xs={12}>
                    <Collapse in={filterOpen}>
                        <Card>
                            <Box p={4}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Branch</InputLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="soldto_name"
                                            options={companyList}
                                            onChange={(event, newValue) => {
                                                handleChangeSoldTo(newValue);
                                            }}
                                            inputValue={filterData.soldToName}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    onChange={handleChange}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") handleFilter();
                                                    }}
                                                    value={filterData.soldToName}
                                                    variant="outlined"
                                                    name="soldTo"
                                                // label="Company"
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Style</InputLabel>
                                        <TextField
                                            name="phoneNumber"
                                            value={filterData.phoneNumber}
                                            onChange={handleChangeFilter}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") handleFilter();
                                            }}
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>



                                    <Grid item xs={12}>
                                        <Box textAlign={"right"}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleFilter}
                                            >
                                                Filter{" "}
                                                <ArrowForward
                                                    fontSize="small"
                                                    style={{ marginLeft: "5px" }}
                                                />
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Collapse>
                </Grid>

                {
                    submittedData.phoneNumber ||

                        submittedData.soldToName

                        ? (
                            <Grid item xs={12}>
                                <FilteredBox>
                                    <span>Filtered: </span>



                                    {submittedData.phoneNumber && (
                                        <p>
                                            <span>Style: {submittedData.phoneNumber}</span>
                                            <Close
                                                fontSize="small"
                                                onClick={() => handleRemove("phoneNumber")}
                                            />
                                        </p>
                                    )}


                                    {submittedData.soldToName && (
                                        <p>
                                            <span>Branch: {submittedData.soldToName}</span>
                                            <Close
                                                fontSize="small"
                                                onClick={() => handleRemove("soldTo")}
                                            />
                                        </p>
                                    )}



                                </FilteredBox>
                            </Grid>
                        ) : (
                            <Box></Box>
                        )}
                {/* Filter */}

                <Grid item md={10} xs={12}>
                    <div style={{ lineHeight: "1.0", fontFamily: "Poppins" }}>
                        <small>Supplier Lead Time (in Weeks): {props?.navigateState?.filterData.supplierLeadTime}</small>
                        &nbsp;|&nbsp;
                        <small>Cust Lead Time Days: {props?.navigateState?.filterData.custLeadTimeDays}</small>
                        &nbsp;|&nbsp;
                        <small>Coverage Buffer: {props?.navigateState?.filterData.coverageBuffer}</small>
                        &nbsp;|&nbsp;
                        <small>Cycle Frequency: {props?.navigateState?.filterData.cycleFrequency}</small>
                        &nbsp;|&nbsp;
                        <small>Default Demand: {props?.navigateState?.filterData.defaultDemand}</small>
                        &nbsp;|&nbsp;
                        <small>Default: {props?.navigateState?.filterData.defaultForecast}</small>
                        &nbsp;|&nbsp;
                        <small>Smoothing: {props?.navigateState?.filterData.smoothing}</small>
                        &nbsp;|&nbsp;
                        <small>Sales Exclude Months: {props?.navigateState?.filterData.salesExcludeMonths}</small>
                        &nbsp;|&nbsp;
                        <small>Use Sales Data Since (in Days): {props?.navigateState?.filterData.useSalesDataSince}</small>
                        &nbsp;|&nbsp;
                        <small>Global Alloc Ramping: {props?.navigateState?.filterData.globalAllocRamping}</small>
                        &nbsp;|&nbsp;
                        <small>Global Sales: {props?.navigateState?.filterData.globalSales}</small>
                        &nbsp;|&nbsp;
                        <small>Mean Trimming: {props?.navigateState?.filterData.meanTrimming}</small>
                        &nbsp;|&nbsp;
                        <small>Other Settings: {props?.navigateState?.filterData.otherSettings}</small>
                    </div>
                </Grid>
                <Grid item md={2} xs={12}>
                    {props?.navigateState?.filterData && (

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(event) => {

                                if (props?.navigateState?.filterData)
                                    navigate(PATH.REPLENISHMENT_FORECAST, {
                                        state: {
                                            filterData: props?.navigateState?.filterData,
                                        },
                                        target: "_blank",
                                    });
                                <Link
                                    to={{
                                        pathname: PATH.REPLENISHMENT_FORECAST,
                                        state: {
                                            filterData: props?.navigateState?.filterData,
                                        },
                                    }}
                                    target="_blank"
                                />;

                            }}
                        >
                            <ArrowBack sx={{ marginRight: "5px" }} fontSize="small" />
                            Back to forecast
                        </Button>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <AdjustedAllocationTable
                        columns={columnsList}
                        rows={rowsList}
                        setRows={setRowsList}
                        getLoading={getLoading}
                        footer={true}
                    //sort={true}
                    //handleView={handleView}
                    //handleSort={handleSort}
                    //loadingList={loadingList}
                    // progress={progress}
                    // complete={complete}
                    // com={com}
                    //handleChangeRowsPerPage={handleChangeRowsPerPage}
                    //handleChangePage={handleChangePage}

                    // buttonLoader={buttonLoader}
                    //direction={direction}
                    //currentColumn={currentColumn}
                    //page={page}
                    //total={total && total}
                    //fromTable={from}
                    //toTable={to}
                    //rowsPerPage={rowsPerPage}
                    //filterData={filterData}
                    />
                </Grid>

                <Grid item md={8} xs={12}>
                    <Header>
                        {request !== "" ? (
                            <h2>You are currently viewing request: {request[1]}({moment(request[2]).format("ddd, MMM Do YYYY, h:mm a")})</h2>
                        ) : <h2>View allocations of any request here:</h2>}
                    </Header>
                </Grid>


                <Grid item xs={12}>
                    <AdjustedAllocationTable
                        columns={columns}
                        rows={rows}
                        setRows={setRows}
                        //sort={true}
                        //handleView={handleView}
                        //handleSort={handleSort}
                        loading={loading}
                        progress={progress}
                        complete={complete}
                        com={com}
                    //handleChangeRowsPerPage={handleChangeRowsPerPage}
                    //handleChangePage={handleChangePage}

                    // buttonLoader={buttonLoader}
                    //direction={direction}
                    //currentColumn={currentColumn}
                    //page={page}
                    //total={total && total}
                    //fromTable={from}
                    //toTable={to}
                    //rowsPerPage={rowsPerPage}
                    //filterData={filterData}
                    />
                </Grid>


            </Grid>


            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AdjustedAllocation;
