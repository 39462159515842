// import { useEffect, useState } from "react";
// import { httpclient } from "../utils/httpClient";

// const useApiCall = (
//   url,
//   urlHeader = "GET",
//   dependencies = [],
//   overrideDataField,
//   requestData
// ) => {
//   /**
//    * Custom hook that handles API calls.
//    * @param {string} url - The URL of the API endpoint.
//    * @param {string} method - The HTTP method (GET, POST, PUT, etc.).
//    * @param {Array} dependencies - An optional array of dependencies that triggers the API call when changed.
//    * @param {Object} requestData - Data to be sent with the request (for POST, PUT, etc. requests).
//    * @returns {Object} - An object containing the loading state, error message, data, and page information.
//    */
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState("");
//   const [pageInfo, setPageInfo] = useState({});

//   useEffect(() => {
//     setLoading(true);
//     const config = {
//       urlHeader,
//       url,
//       data: requestData, // Include data for POST, PUT, etc. requests
//     };

//     httpclient
//       .request(config)
//       .then(({ data }) => {
//         if (data.success) {
//           setLoading(false);
//           setData(overrideDataField ? data[overrideDataField] : data.data);
//           setPageInfo(data.meta);
//         }
//       })
//       .catch((err) => {
//         setLoading(false);
//         if (err.response && err.response.status === 401) {
//           localStorage.clear();
//           window.location = "/login";
//         } else {
//           setError("An error occurred while fetching data.");
//         }
//       });
//   }, dependencies);

//   return { loading, error, data, pageInfo };
// };

// export default useApiCall;


import { useEffect, useState } from "react";
import { httpclient } from "../utils/httpClient";

const useApiCall = (
  url,
  urlHeader = "GET",
  dependencies = [],
  overrideDataField,
  requestData
) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [pageInfo, setPageInfo] = useState({});

  useEffect(() => {
    let isMounted = true; // Flag to track component unmounting

    const fetchData = async () => {
      setLoading(true);
      const config = {
        urlHeader,
        url,
        data: requestData,
      };

      try {
        const response = await httpclient.request(config);

        if (isMounted) {
          const responseData = response.data;
          if (responseData.success) {
            setData(overrideDataField ? responseData[overrideDataField] : responseData.data);
            setPageInfo(responseData.meta);
            
          } else {
            setError("API request failed.");
          }
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setLoading(false);
          if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location = "/login";
          } else {
            setError("An error occurred while fetching export data.");
          }
        }
      }
    };

    fetchData();

    return () => {
      // Cleanup function to set isMounted to false when component unmounts
      isMounted = false;
    };
  }, dependencies); // Only re-run the effect if dependencies change

  return { loading, error, data, pageInfo};
};

export default useApiCall;

