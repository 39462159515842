// import React, { useEffect, useState } from "react";
// import SyncSettings from "./components/SyncSettings";
// import { httpclient } from "../../../utils/httpClient";
// import { CircularProgress, Grid, Typography } from "@mui/material";
// import LoadingScreen from "../../../Components/LoadingScreen";

// const Sync = () => {
//   const [syncLoading, setSyncLoading] = useState(true);
//   const [syncList, setSyncList] = useState([]);

//   useEffect(() => {
//     httpclient.get(`web-routing-setting-info`).then(({ data }) => {
//       if (data.success) {
//         setSyncLoading(false);
//         setSyncList(data.data);
//       } else {
//         setSyncLoading(false);
//       }
//     });
//   }, []);

//   return (
//     <>
//       {syncList.length > 0 ? (
//         <>
//           <SyncSettings
//             label="Bunnings Sync Settings"
//             companyId={28}
//             fetchedSyncSettingsDetails={syncList}
//           />
//           <SyncSettings
//             label="MyDeals Sync Settings"
//             companyId={16}
//             fetchedSyncSettingsDetails={syncList}
//           />
//           <SyncSettings
//             label="Dsco Sync Settings"
//             companyId={5}
//             fetchedSyncSettingsDetails={syncList}
//           />
//           <SyncSettings
//             label="T&W Sync Settings"
//             companyId={1}
//             fetchedSyncSettingsDetails={syncList}
//           />
//         </>
//       ) : (
//         <LoadingScreen message="Syncing Your Data ..." />
//       )}
//     </>
//   );
// };

// export default Sync;

import React, { useEffect, useState } from "react";
import SyncSettings from "./components/SyncSettings";
import { httpclient } from "../../../utils/httpClient";
import { CircularProgress, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import LoadingScreen from "../../../Components/LoadingScreen";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Sync = () => {
  const [syncLoading, setSyncLoading] = useState(true);
  const [syncList, setSyncList] = useState([]);
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  useEffect(() => {
    httpclient.get(`web-routing-setting-info`).then(({ data }) => {
      if (data.success) {
        setSyncLoading(false);
        setSyncList(data.data);
      } else {
        setSyncLoading(false);
      }
    });
  }, []);

  const getLabel = (companyId) => {
    switch (companyId) {
      case 28:
        return "Bunnings Sync Settings";
      case 16:
        return "MyDeal Sync Settings";
      case 5:
        return "DSCO Sync Settings";
      case 1:
        return "T&W Sync Settings";
      default:
        return "Unknown";
    }
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  console.log("sync", syncList)

  return (
    <>
      {syncLoading ? (
        <LoadingScreen message="Syncing Your Data ..." />
      ) : (
        <Grid container spacing={2}>
          {syncList.map((sync, index) => (
            <Grid item xs={12} key={index}>
              <Accordion
                expanded={expandedAccordion === `panel${index}`}
                onChange={handleChangeAccordion(`panel${index}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography style={{ fontWeight: 'bold' }}>{getLabel(sync.companyId)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <SyncSettings
                    companyId={sync.companyId}
                    fetchedSyncSettingsDetails={syncList}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default Sync;
