import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import moment from 'moment';


export default function BasicTable(props) {
  return (
    <TableContainer component={Paper} style={{ overflowY:"auto", height:"500px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.columns.map(col => (
                <TableCell style={{ fontWeight:"700" }}>{col.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        {props.rows.length > 0 ?
        <TableBody>
          {props.rows.map((row) => {
            var allStockTotal = 0;
            
            if(row.stock_on_hand && row.stock_on_hand[5]){
              var newStockArr = Object.values(row.stock_on_hand);
              newStockArr.map((stock,index) => {
                return allStockTotal = allStockTotal + stock.actualSOH
              });
            }
            return (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                {props.columns.map((col,index) => (
                    col.id === "reason" ?
                    <TableCell component="th" scope="row" style={{ width:"100%", display:"flex" }}>
                      {row.reportType !== "exceptionalOrder" ? <Check color='primary' fontSize='small' /> : <Clear color='error' fontSize='small' />}
                      {row[col.id]}
                    </TableCell>
                    :
                    <TableCell component="th" scope="row">
                        {
                        col.id === "erpBranchID" ? row.branch_details ? row.branch_details?.erpBranchID : row.erpBranch?.erpBranchID :
                        col.id === "erpBranchName" ? row.branch_details ? row.branch_details?.branchName : row.erpBranch?.branchName :
                        col.id === "erpAccountNameNetSuite" ? row.customer?.erpAccountName:
                        col.id === "stockName" ? (row.stock.stockName || row.stock.ciStockName || row.stock.stockNameDefault) :
                        col.id === "actualSOH" ? Math.max(row.actualSOH, 0) : 
                        
                        col.id === "LOCNO" ? row.LOCATION.LOCNO : 
                        col.id === "LNAME" ? row.LOCATION.LNAME : 

                        col.id === "stockCode" ? (row.stockCode || row.ciStockCode || row.stock.stockCode) :
                        col.id === "stockNameDefault" ? (row.stockNameDefault || row.ciStockName) :

                        col.id === "erpAccountCustomerID" ? row.erpAccountCustomerID.erpAccountCustomerID :
                        col.id === "erpAccountCustomerIDNet" ? row.customer.erpAccountCustomerID :
                        col.id === "erpAccountName" ? row.erpAccountCustomerID.erpAccountName :
                        col.id === "erpAccountNameNet" ? row.customer.erpAccountName :
                        
                        col.id === "startDate" ? moment(row.startDate).format("ddd, MMM Do YYYY, h:mm:ss a") :
                        col.id === "endDate" ? moment(row.endDate).format("ddd, MMM Do YYYY, h:mm:ss a") :

                      
                        col.id === "quantity" ? <span style={{ color: row.reportType !== "exceptionalOrder" ? 'inherit': 'red' }}>{(row.quantity)}</span>  :
                        col.id === "unitPrice" ? <span style={{ color: row.reportType !== "exceptionalOrder" ? 'inherit': 'red' }}>{("$" + row.unitPrice)}</span> :
                        col.id === "actualPrice" ? <span style={{ color: row.reportType !== "exceptionalOrder" ? 'inherit': 'red' }}>{("$" + row.price.ActualPrice)}</span> :
                        col.id === "policyPrice" ? ("$" + row.price_policy_of_stock.price) :
                        col.id === "price" ? ("$" + row.price.toFixed(2)) :
                        col.id === "price1" ? ("$" + parseFloat(row.price).toFixed(2)) :
                        col.id === "latestCost" ? ("$" + (row.latestCost.toFixed(2))) :
                        col.id === "averageCost" ? ("$" + (row.averageCost.toFixed(2))) :
                        col.id === "supplierCost" ? ("$" + (row.supplierCost.toFixed(2))) :
                        col.id === "active" ? (row.stock.Active === "Y" ? <Check fontSize='small' color="primary" /> : <Clear fontSize='small' color='error' />) :
                          row[col.id]
                        }
                    </TableCell> 

                ))}
            </TableRow>
)})}
        </TableBody>
        :
        <TableBody>
          <TableRow style={{ position:"relative",width:"100%" }}>
            <Box textAlign="center" style={{ position:"absolute", right:"50%" }} mt={3}>
              No Data Found
            </Box>
          </TableRow>
        </TableBody>
        }
      </Table>
    </TableContainer>
  );
}
